import {
  CaptureAttribute,
  FilePondFile,
  ProcessServerConfigFunction,
} from 'filepond';
import { html, PropertyValues } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { property, state } from 'lit/decorators.js';
import { EntityTypes, type UploadedFile } from './filepond-wrapper.wc';
import { BaseElement, customElement } from '../../base-element';
import { Translate } from '../../base-element/mixins/translation-mixin';
import './filepond.scss';

interface FilepondProps {
  accountId?: string;
  entityId?: string;
  entityType?: EntityTypes;
  pictureSrc?: string;
  name?: string;
  readonly?: boolean;
  text?: string;
  accept?: string;
  onChange?: (url?: string) => void;
}

type ConfigureProcessFunctionParams = Pick<
  FilepondProps,
  'accountId' | 'entityId' | 'entityType' | 'onChange'
>;

type ProcessServerConfigFunctionParams =
  Parameters<ProcessServerConfigFunction>;

interface ConfigureProcessFunction {
  (props: ConfigureProcessFunctionParams): ProcessServerConfigFunction;
}

type SetProfilePictureParams = ConfigureProcessFunctionParams & {
  file?: ProcessServerConfigFunctionParams[1];
  load?: ProcessServerConfigFunctionParams[3];
  error?: ProcessServerConfigFunctionParams[4];
  progress?: ProcessServerConfigFunctionParams[5];
  abort?: ProcessServerConfigFunctionParams[6];
};

interface SetProfilePicture {
  (props: SetProfilePictureParams): {
    abort: SetProfilePictureParams['abort'];
  };
}

const uploadFile: SetProfilePicture = ({
  accountId,
  entityId,
  entityType = EntityTypes.USER,
  file,
  load,
  error,
  progress,
  abort,
  onChange,
}) => {
  console.log('>>>uploadFile', file);
  const url = `${
    import.meta.env.FE_ADMIN_URL
  }/rest/upload-file-picture/${accountId}/${entityType}/${entityId}`;

  const request = new XMLHttpRequest();
  request.withCredentials = true;
  request.open('POST', url);

  // Should call the progress method to update the progress to 100% before calling load
  // Setting computable to false switches the loading indicator to infinite mode
  request.upload.onprogress = (e) => {
    progress?.(e.lengthComputable, e.loaded, e.total);
  };

  // Should call the load method when done and pass the returned server file id
  // this server file id is then used later on when reverting or restoring a file
  // so your server knows which file to return without exposing that info to the client
  request.onload = () => {
    if (request.status >= 200 && request.status < 300) {
      // the load method accepts either a string (id) or an object
      load?.(request.responseText);
      const resultURL = JSON.parse(request.response)?.url;
      onChange?.(resultURL);
    } else {
      // Can call the error method if something is wrong, should exit after
      error?.(file ? 'Error uploading file' : 'Error deleting file');
    }
  };

  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  const body = file ? formData : undefined;

  request.send(body);

  // Should expose an abort method so the request can be cancelled
  return {
    abort: () => {
      // This function is entered if the user has tapped the cancel button
      request.abort();

      // Let FilePond know the request has been cancelled
      abort?.();
    },
  };
};

const configureProcessFunction: ConfigureProcessFunction =
  ({ accountId, entityId, entityType, onChange }) =>
  (...args) => {
    const [, file, , load, error, progress, abort] = args;

    uploadFile({
      accountId,
      entityId,
      entityType,
      file,
      load,
      error,
      progress,
      abort,
      onChange,
    });
  };

@customElement('ps-filepond')
export class FilepondWC extends Translate(BaseElement) {
  @property() accept?: string;

  @property({ reflect: true }) accountId?: string;

  @property({ reflect: true }) entityId?: string;

  @property({ reflect: true }) entityType?: EntityTypes;

  @property({ reflect: true }) pictureSrc?: string;

  @property() name?: string;

  @property() text?: string;

  @property({ reflect: true }) onChange?: (url?: string) => void;

  @property({ type: Boolean }) readonly?: boolean;

  @property({ type: Boolean }) allowMultiple?: boolean;

  @property({ type: Boolean }) required?: boolean;

  @property() captureMethod?: CaptureAttribute;

  @property({ type: Boolean }) isPlaceholder?: boolean;

  @state() files: UploadedFile[] = [];

  @state() isNewFile: boolean;

  firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated?.(_changedProperties);
    if (this.pictureSrc && !this.isPlaceholder) {
      fetch(this.pictureSrc)
        .then((r) => r.blob())
        .then((blobFile) => {
          this.files = [new File([blobFile], 'file', { type: 'image/png' })];
        });
    } else {
      this.isNewFile = true;
    }
  }

  render() {
    return this.isPlaceholder && this.pictureSrc
      ? html`
          <div class="c-filepond__placeholder">
            <img
              alt=""
              class="c-filepond__placeholder-image"
              src=${this.pictureSrc}
            />
          </div>
        `
      : html`
          <ps-filepond-wrapper
            acceptedFileTypes=${ifDefined(this.accept)}
            ?disabled=${this.readonly}
            .files=${this.files}
            name=${ifDefined(this.name)}
            labelIdle=${this.text
              ? `<span class="filepond--label-action">${this.text}</span>`
              : `Drag & Drop your files or <span class="filepond--label-action">Browse</span>`}
            .onupdatefiles=${(e: FilePondFile[]) => {
              this.isNewFile = true;
              this.files = [e[0]?.file];
            }}
            .server=${this.isNewFile
              ? {
                  process: configureProcessFunction({
                    accountId: this.accountId,
                    entityId: this.entityId,
                    entityType: this.entityType,
                    onChange: this.onChange,
                  }),
                }
              : undefined}
          ></ps-filepond-wrapper>
        `;
  }

  createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-filepond': FilepondWC;
  }
  enum PSElementTagNameMap {
    'ps-filepond' = 'ps-filepond',
  }
}
